import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Home/Header";
import MenuBar from "../Home/MenuBar";
import Asset7300 from '../images/Asset 28.png'
import Asset7330 from '../images/Asset 27.png'


function Aboutus() {
  return (
    <div>
      <MenuBar />
      <Header />
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-6">
            {/* <h3>About Us </h3> */}
            <div>
              <h2  style={{fontWeight:'bold'}} className="text-center">About Us</h2>
              <ul style={{ textAlign: "justify" }}>
                <li className="mt-3">
                Order food anywhere, anytime With online food delivery across
              1000+ cities, get delivery of your favourite local & international
              dishes within minutes, even late at night. 
                </li>
                <li className="mt-3">
                 Live order tracking
              & 24*7 customer support Track your food order real-time: right
              from payment confirmation to estimated delivery time. For help,
              chat with our customer support executives 24*7.
                </li>
                <li className="mt-3">
                 Many safe
              payment options & lots of discounts Pay safely & securely with
              UPI, cash on delivery or cards/wallets/netbanking/buy now pay
              later/Sodexo/Simpl. Get exciting deals & offers with up to 60% OFF
              or free dishes from restaurants.
                </li>
       
              </ul>
            </div>
         
          </div>
          <div className="col-md-6 text-center" style={{margin:'auto'}}>
            <img src={Asset7300} alt="img" />
          </div>
          <div className="col-md-6 text-center" style={{margin:'auto'}}>
            <img src={Asset7330} alt="img" />
          </div>
          <div className="col-md-6">
            <ul>
            <li className="mt-3">
                Whatever’s the occasion, we are
              here to serve Hosting a birthday party? Have unexpected guests?
              Forgot to carry tiffin? Not feeling well or too tired to cook?
              Feel like treating yourself, trying a new cuisine or just missing
              an old dish you love? Don’t worry, restaurant is there to help.
              Search for & discover top restaurants, cuisines & dishes Whether
              it's your breakfast, lunch or dinner, find the best around you
              with Domino’s, Pizza Hut, McDonald’s, Starbucks, Subway, Burger
              King, Taco Bell, KFC, Chaayos, Pasta, Burgers, Noodles, Parathas,
              Lassi, Biryani, Idlis, Dal Makhani, Butter Chicken, Paneer
              Makhani, Dosas, Salads, Cakes, Ice Creams, Mithai, Samosas, Momos,
              Sushi…& a lot more. Vegetarian & healthy options Prefer
              vegetarian food? Order from veg-only restaurants or see vegetarian
              dishes in any restaurant. Health conscious? Indulge guilt-free
              with the ‘Healthy’ option on the app. Get meal recommendations
              curated by nutritionists for your dietary needs.
                </li>
            </ul>
      

          </div>
      
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Aboutus;
