import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import Header from "../Home/Header";
import MenuBar from "../Home/MenuBar";
import axios from "axios";
import Loader from "../Home/Loader/Loader";
import NotificationModal from "./NotificationModal";
import {motion} from 'framer-motion'
import Asset28 from '../images/Asset 28.png'

import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

function Notification() {
  const [getNotifications, setGetNotifications] = useState("");
  const [Loading, setLoading] = useState("");
  const [discriptionModal, setDiscriptionModal] = useState(false);
  const [popularData, setPopularData] = useState("");

  //Get Coupon Details
  const getCouponInfo = () => {
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = "https://hotelapsara.co/admin/UserApi/getNotifications";
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    setLoading(true);
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response);
        setLoading(false);

        setGetNotifications(response.data.notifications);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getCouponInfo();
  }, []);

  //Modal

  const notificationModalData = (title, desc) => {
    let modalData = [title, desc];

    setPopularData((item) => [1, ...modalData]);
    return setDiscriptionModal(true);
  };

  return (
    <div>
      {Loading ? (
        <Loader />
      ) : (
        <>
          <MenuBar />
          <Header />
          <div style={{ backgroundColor: "#f4ede4" }}>
            <div className="container p-3">
              {getNotifications &&
                getNotifications.map((item) => {
                  //   console.log(item);

                  return (
                    <>
                      <div
                        class="card mt-2 mb-2"
                        style={{
                          backgroundColor: "#F7F4F0",
                          border: "1px solid #66432D",
                          borderRadius: "10px",
                          cursor:'pointer'
                        }}
                        initial={{x:"100vw"}}
                        animate={{x:0}}
                        transition={{delay:0.2,duration:0.5}}
                        onClick={() =>
                          notificationModalData(
                            item.notification_title,
                            item.notification_description
                          )
                        }
                      >
                        <div class="card-body">
                          <div className="d-flex">
                            <img
                              src={`https://hotelapsara.co/admin/${item.notification_image}`}
                              alt="img"
                              style={{
                                width: "100px",
                                borderRadius: "10em",
                                margin: "10 auto",
                                marginTop: "10px",
                                height: "100px",
                              }}
                            />
                            <div className="notification_title ">
                              <h5 class="card-title " style={{}}>
                                Notification : {item.notification_title}
                              </h5>

                              <p class="card-text">
                                Discription: {item.notification_description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
          {discriptionModal === true ? (
            <NotificationModal
              hide={() => setDiscriptionModal(false)}
              title={popularData[1]}
              desc={popularData[2]}
            />
          ) : (
            ""
          )}
          <Footer />
        </>
      )}
    </div>
  );
}

export default Notification;
