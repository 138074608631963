import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Home/Header'
import MenuBar from '../Home/MenuBar'

function Terms() {
  return (
    <div>
        <MenuBar/>
        <Header/>
        <div className='container'>
        <div className='row mt-5 mb-5'>
            <div className='col-md-8 offset-md-2'>
              <h2  style={{fontWeight:'bold'}} className="text-center">Terms And Condition</h2>
              <ul style={{ textAlign: "justify" }}>
                <li className="mt-3">
                Order food anywhere, anytime With online food delivery across
              1000+ cities, get delivery of your favourite local & international
              dishes within minutes, even late at night. 
                </li>
                <li className="mt-3">
                 Live order tracking
              & 24*7 customer support Track your food order real-time: right
              from payment confirmation to estimated delivery time. For help,
              chat with our customer support executives 24*7.
                </li>
                <li className="mt-3">
                 Many safe
              payment options & lots of discounts Pay safely & securely with
              UPI, cash on delivery or cards/wallets/netbanking/buy now pay
              later/Sodexo/Simpl. Get exciting deals & offers with up to 60% OFF
              or free dishes from restaurants.
                </li>
       
              </ul>
            </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Terms