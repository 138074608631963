import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../Footer/Footer'
import Header from '../Home/Header'
import MenuBar from '../Home/MenuBar'
import thanks from '../images/thanks6.png'

function Thanks() {
    useEffect(()=>{
        // localStorage.removeItem("cartLength");
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    },[])
  return (
    <div>
        <MenuBar/>
        <Header/>
         <div className="container marg_top">
        <div className="row">
          <section class="login-main-wrapper">
            <div class="main-container">
              <div class="login-process">
                <div class="login-main-container">
                  <div class="thankyou-wrapper">
                    <h1 className='mt-3'>
                      <img
                        src={thanks}
                        alt="thanks"
                        className="thnaks_imgsss "
                      />
                    </h1>
                    <div className="d-flex justify-content-center">
                    <p>Your order has been placed successfully !!!<br/>
                      You can view your order history by going to below link. <br/>
               
                      Thanks for book your Order with us online!
               </p>
                    </div>
             
                    <div className="d-flex justify-content-center">
                      <Link to="/" className='btn buttons'> <i class="fa-solid fa-backward"></i>&nbsp;&nbsp;Back to home</Link>
                      <Link to={`/home/my_orders`} className='btn buttons'>
                      <i class="fa-solid fa-list-ul"></i>&nbsp;&nbsp;My Orders
                      </Link>
                    </div>

                    <div class="clr"></div>
                  </div>
                  <div class="clr"></div>
                </div>
              </div>
              <div class="clr"></div>
            </div>
          </section>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Thanks