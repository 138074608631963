import React, { useState } from "react";
import Asset30 from "../images/Asset 30.png";
import Asset14 from "../images/Asset 14.png";
import axios from "axios";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";



function Modal(props) {
  const [loading, setLoading] = useState(false);
  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };

  // console.log(props);
  let user_id = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const [count, setCount] = useState(1);

  let modalStyle = {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.8)",

  };

  //prevent Minus Count
  const dec = () => {
    if (count <= 1) {
      return;
    } else {
      setCount(count - 1);
    }
  };

  // Add To Cart

  const addToCart = async (id) => {
    if(!user_id){
      navigate('/login')
    }
    else{
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("menu_id", id);
    formData.append("quantity", count);
    //append the values with key, value pair

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
    const url = "https://hotelapsara.co/admin/UserApi/addtocart";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      setLoading(true);

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          window.location.reload(false);
          toast.success("Item Added To Cart Successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) => {
          console.log(error);
        });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  };

  return (
    <div>
      <div
        class="modal"
        show
        fade
        style={modalStyle}
        variants={dropIn}
        initial="hidden"
        animate="visible"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content modal-1">
            <div class="modal-header">
              <img
                src={`https://hotelapsara.co/admin/${props.image}`}
                alt="img"
                className="modal_image"
              ></img>

              <button
                type="button"
                class="btn-close"
                onClick={props.hide}
              ></button>
            </div>
            <div class="modal-body">
              <h3 class="modal-title" id="exampleModalToggleLabel">
                {props.title}
              </h3>

              <p>{props.desc}</p>
              <div class="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between">
                    <h2>Rs. {props.price}</h2>
                    <div className="text_down">
                      <img
                        src={Asset30}
                        class="cart_minus_img sub"
                        id="sub"
                        role="button"
                        alt="img"
                        onClick={dec}
                      />
                      <input
                        class=" cart_quantity_input count"
                        type="text"
                        disabled
                        value={count}
                      />
                      <img
                        src={Asset14}
                        class="cart_plus_img add"
                        id="add"
                        role="button"
                        alt="img"
                        onClick={() => setCount(count + 1)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 d-grid gap-2">
                {!loading && (
                  <button
                    class="btn addcart"
                    type="button"
                    onClick={() => addToCart(props.id)}
                  >
                    Add to cart
                  </button>
                )}
                {loading && (
                  <button className="btn addcart" type="button" disabled>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    Wait...
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
