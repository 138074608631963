import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import Header from "../Home/Header";
import MenuBar from "../Home/MenuBar";
import axios from "axios";
import Loader from "../Home/Loader/Loader";
import { useParams } from "react-router-dom";
import CancelReasonModal from "./CancelReasonModal";
import CancelledModal from "./CancelledModal";
import { motion } from "framer-motion";
import Asset95 from "../images/Asset95.png";

function Delivery() {
  let user_id = localStorage.getItem("user_id");
  let order_id = localStorage.getItem("order_id");

  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [orderInsideData, setOrderInsideData] = useState([]);
  const [cancelReasonModal, setCancelReasonModal] = useState(false);
  const [itemWillCancelled, setItemWillCancelled] = useState(false);
  const [cancelledModal, setCancelledModal] = useState(false);
  const [errorData, setErrorData] = useState(false);
  const [nullMessage, setNullMessage] = useState(false);
  const [deliveryPage, setDeliveryPage] = useState(false);
  const [popularData, setPopularData] = useState("");

  // const params = useParams();
  // useEffect(() => {

  //   console.log(params, "params");
  // }, [params]);

  //Get Orders
  const getDataByID = async () => {
    // setLoading(true);
    // setInterval(() => {
    //   console.log("hello")
    // }, 1000)
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/service_data?service_id=${id}`;
    const url = `https://hotelapsara.co/admin/UserApi/getUserAllOrders?user_id=${user_id}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    const apiResponse = await axios.get(url);
    const myOrderItems = apiResponse.data.orders;
    let promises = [];

    myOrderItems.forEach(async (element) => {
      if (element.order_id) {
        promises.push(getOrderById(user_id, element.order_id))
        // const orderResponse = await getOrderById(user_id, element.order_id);
        // myOrderItems.order_items = orderResponse;
        // console.log(myOrderItems, 'myOrderItems');

      }
    });
    const res = await Promise.all(promises)
    myOrderItems.forEach((element, index) => {
      element.order_items = res[index];
    });
    console.log(myOrderItems, 'apiResponse');
    setDeliveryPage(true);

    setOrderData(myOrderItems);
    // axios
    //   .get(url, formData, config)
    //   .then((response) => {
    //     // console.log(response);
    //     if (response.data.message === "Cart is empty") {
    //       setNullMessage(true);
    //       setLoading(false);
    //       setDeliveryPage(false);
    //     } else {
    //       setLoading(false);
    //       setDeliveryPage(true);

    //       setOrderData(response.data.orders);

    //       {
    //         response.data.orders &&
    //           response.data.orders.map((item, i) => {
    //             // console.log(item);
    //             return getDataByOrder_id(item.order_id);
    //           });
    //       }
    //     }
    //   })

    //   .catch((error) => {
    //     console.log(error);
    //     setLoading(false);
    //   });
  };

  useEffect(() => {
    getDataByID();
  }, []);

  const getOrderById = async (user_id, order_id) => {
    const url = `https://hotelapsara.co/admin/UserApi/getOrderedItemsofUser?user_id=${user_id}&order_id=${order_id}`;
    const apiResponse = await axios.get(url);
    return apiResponse.data.items;
  }

  //For Inside content
  const getDataByOrder_id = (order_id) => {
    let formData = new FormData();

    // const url = `https://192.168.1.16/vipdoor/UserApi/service_data?service_id=${id}`;
    const url = `https://hotelapsara.co/admin/UserApi/getOrderedItemsofUser?user_id=${user_id}&order_id=${order_id}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    setLoading(true);
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response);
        setLoading(false);

        setOrderInsideData(response.data.items);
      })

      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // console.log(orderInsideData)

  //ReasonModal
  const discriptionModalData = (date, time, id) => {
    let modalData = [date, time, id];

    setPopularData((item) => [1, ...modalData]);
    return setCancelReasonModal(true);
  };

  //Cncelled Modal
  const cancelledModalData = (date, time, id) => {
    let modalData = [date, time, id];

    setErrorData((item) => [1, ...modalData]);
    return setCancelledModal(true);
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MenuBar />
          <Header />

          {nullMessage && (
            <>
              <div>
                <div
                  className="text-center p-3"
                  style={{ backgroundColor: "#F7F4F0" }}
                >
                  <img
                    src={Asset95}
                    alt="img"
                    style={{ width: "200px", height: "200p" }}
                  ></img>
                </div>
              </div>
            </>
          )}

          {deliveryPage && (
            <>
              <div className="container mt-1">
                <div class="row category_row mb-3">
                  <div class="d-flex">
                    <a role="button">Running</a>

                    <a role="button" style={{ marginLeft: "30px" }}>
                      History
                    </a>
                  </div>
                  <div class="hb"></div>
                  {/* <div class="hb33"></div> */}
                </div>
              </div>

              {orderData &&
                orderData.map((item, i) => {
                  //             const today= new Date();
                  //             var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                  // let api_time= item.order_time
                  //            if(time - item.order_time){
                  //             setCancelledModal(true)
                  //            }
                  //            else{
                  //              setCancelReasonModal(true)
                  //            }
                  // console.log(time)
                  // console.log(cancelReasonModal)
                  return (
                    <>
                      <div
                        class="card order-history55 p-3"
                        style={{ backgroundColor: "#F7F4F0" }}
                        key={i}
                      >
                        <div class="card-body card_delivery">
                          {" "}
                          <h6 class="card-title55 title55">
                            Ordered On {item.order_date} {item.order_time}
                            <span class="status55">
                              Status:{" "}
                              {cancelledModal && cancelledModal ? (
                                <>
                                  <motion.span
                                    class="stat-25"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      cancelledModalData(
                                        item.order_date,
                                        item.order_time,
                                        item.order_id
                                      )
                                    }
                                    whileHover={{ scale: 1.1 }}
                                  >
                                    Cancel
                                  </motion.span>{" "}
                                </>
                              ) : (
                                <>
                                  <motion.span
                                    class="stat-25"
                                    onClick={() =>
                                      discriptionModalData(
                                        item.order_date,
                                        item.order_time,
                                        item.order_id
                                      )
                                    }
                                    whileHover={{ scale: 1.1 }}
                                  >
                                    Cancel
                                  </motion.span>{" "}
                                </>
                              )}
                            </span>
                          </h6>
                          {item.order_items &&
                            item.order_items.map((items, index) => {
                              // console.log(items);
                              return (
                                <>
                                  <div class="row disc55" key={index}>
                                    <div class="col-3 ">
                                      <img
                                        src={`https://hotelapsara.co/admin/${items.item_image}`}
                                        alt="salman"
                                        class="prod-img55"
                                      />
                                    </div>
                                    <div class="col-9 order-disc55">
                                      <p>
                                        {items.category}
                                        <br />
                                        {items.item_name}
                                        <br />
                                      </p>
                                      <h6>
                                        {items.item_quantity} x{" "}
                                        {items.item_price}
                                      </h6>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          <div class="row detail55">
                            <div class="col-4">
                              <p>
                                Order ID:{" "}
                                <span class="order-span55">
                                  {item.order_id}
                                </span>{" "}
                              </p>
                            </div>
                            <div class="col-4 order-3">
                              <p>
                                Total Amount{" "}
                                <span class="order-span55">
                                  {item.total_amount}
                                </span>{" "}
                              </p>
                            </div>
                            <div class="col-4 order-25">
                              <p>
                                Payment:{" "}
                                <span class="order-span55">
                                  {item.payment_type}
                                </span>{" "}
                              </p>
                            </div>
                            
                          </div>
                          <div class="div shipping55">
                            <p>Shipping Address</p>
                            <p>
                              Name: <span class="addr-15">{item.username}</span>
                            </p>
                            <p>
                              Mobile No:{" "}
                              <span class="addr-25">{item.user_mobile}</span>
                            </p>
                            <p>
                              Address:{" "}
                              <span class="addr-35">
                                {item.address},{""}
                                {item.city},{" "}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </>
          )}

          {cancelReasonModal === true ? (
            <CancelReasonModal
              hide={() => setCancelReasonModal(false)}
              time={popularData[2]}
              id={popularData[3]}
            />
          ) : (
            ""
          )}
          {cancelledModal === true ? (
            <CancelledModal
              hide={() => setCancelledModal(false)}
              time={popularData[2]}
              id={popularData[3]}
            />
          ) : (
            ""
          )}
          <Footer />
        </>
      )}
    </div>
  );
}

export default Delivery;
