import React from "react";
import Asset7 from "../images/Asset 7.png";
import Asset6 from "../images/Asset 6.png";
import Asset1px from "../images/Logo.png";
import Asset3 from "../images/Asset 3.png";
import Asset5 from "../images/Asset 5.png";
import Asset8 from "../images/Asset 8.png";
import Asset4 from "../images/ab.png";
import Asset58 from "../images/Asset 58.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { motion } from "framer-motion";

function Header(props) {
  let user_name = localStorage.getItem("user_name");
  let user_id = localStorage.getItem("user_id");
  let cartLength = localStorage.getItem("cart");

  const navigate = useNavigate();

  function Logout() {
    {
      localStorage.clear();
      navigate("/");
      toast.info("You are Logout", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  return (
    <div>
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container">
          <Link class="navbar-brand" to="/">
            <motion.img
              src={Asset1px}
              class="Restaurant_logo"
              whileHover={{ scale: 1.1 }}
              style={{ width: "150px" }}
            />
          </Link>
          <div class="d-flex cart">
            <Link
              class="header_txt1 cart1 position-relative"
              to={`/home/${user_id}/mycart`}
              style={{ textDecoration: "none" }}
            >
              <img src={Asset58} class="cart1" alt="img" />
              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {cartLength > 0 ? cartLength : ""}
              </span>
            </Link>
          </div>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <NavLink
                  class="nav-link active"
                  aria-current="page"
                  to="/"
                  style={{ textDecoration: "none" }}
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  id="nav-link-1"
                >
                  <div class="header_item">
                    <div class="header_vb"></div>
                    <div class="header_item_1 text-center align-items-center">
                      {/* <motion.img
                        src={Asset6}
                        class="header_img"
                        whileHover={{ scale: 1.1 }}
                      /> */}
                      <i
                        class="fa-solid fa-house"
                        style={{ fontSize: "22px" }}
                      ></i>
                      <div class="header_txt" id="txt_1">
                        Home
                      </div>
                    </div>
                    <div class="header_vb"></div>
                  </div>
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink
                  to="/aboutus"
                  style={{ textDecoration: "none" }}
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  <div class="header_item">
                    <div class="header_item_1 text-center align-items-center">
                      {/* <motion.img
                        src={Asset4}
                        class="header_img1"
                        whileHover={{ scale: 1.1 }}
                      /> */}
                      <i
                        class="fa-solid fa-circle-info"
                        style={{ fontSize: "22px" }}
                      ></i>

                      <div class="header_txt" id="txt_2">
                        About Us
                      </div>
                    </div>
                    <div class="header_vb"></div>
                  </div>
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink
                  class="nav-link"
                  to="/contactus"
                  style={{ textDecoration: "none" }}
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  <div class="header_item">
                    <div class="header_item_1 text-center align-items-center">
                      {/* <motion.img
                        src={Asset5}
                        class="header_img1"
                        whileHover={{ scale: 1.1 }}
                      /> */}
                      <i
                        class="fa-solid fa-address-book"
                        style={{ fontSize: "22px" }}
                      ></i>

                      <div class="header_txt">Contact Us</div>
                    </div>
                    <div class="header_vb"></div>
                  </div>
                </NavLink>
              </li>
            </ul>
            {user_id ? (
              <>
                <div class="dropdown User_drop">
                  <button
                    class="btn header_user_btn dropdown-toggle"
                    type="button"
                    id="Button1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {user_name && user_name.slice(0, 12)}
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="Button1">
                    <li>
                      <Link
                        class="dropdown-item"
                        to={`/home/profile/${user_id}`}
                      >
                        View Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        class="dropdown-item"
                        to={`/home/address/${user_id}`}
                      >
                        Address
                      </Link>
                    </li>
                    <li>
                      <a class="dropdown-item" onClick={Logout}>
                        {" "}
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <>
           
                <NavLink
                
                  to="/login"
                  style={{ textDecoration: "none",color:"#583026" }}
                  // className={({ isActive }) =>
                  //   isActive ? "nav-link active" : "nav-link"
                  // }
                >
                  <div class="header_item">
                    <div class="header_item_1 text-center align-items-center">
                      {/* <motion.img
                        src={Asset5}
                        class="header_img1"
                        whileHover={{ scale: 1.1 }}
                      /> */}
                   
                      <i class="fa-solid fa-user"  style={{ fontSize: "22px" }}></i>
                      <div class="header_txt">Login</div>
                    </div>
                 
                  </div>
                  </NavLink>
             
              </>
            )}
          </div>
          <div class="d-flex">
            <div class="header_vb" id="vb_2"></div>
            <Link
              class="header_txt1 cart2 position-relative"
              to={`/home/${user_id}/mycart`}
              style={{ textDecoration: "none" }}
            >
              <motion.img
                src={Asset58}
                class="cart2"
                alt="img"
                whileHover={{
                  scale: 1.1,
                }}
              />
              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {cartLength > 0 ? cartLength : ""}
              </span>
            </Link>

            <div class="header_vb" id="vb_2"></div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
