import React from 'react'
import Asset48 from '../images/foot_img.png'
import Asset47 from '../images/Asset 47.png'
import Asset45 from '../images/Asset 45.png'
import Asset46 from '../images/Asset 46.png'
import Asset41 from '../images/Asset 41.png'
import Asset42 from '../images/Asset 42.png'
import Asset43 from '../images/Asset 43.png'
import Asset44 from '../images/Asset 44.png'
import Asset49 from '../images/Asset 49.png'
import Asset1px from '../images/foot_logo.png'
import { Link } from 'react-router-dom'



function Footer() {
  return (
    <div>
          <div class="footer_container1">
        <div class="container">
            <div class="row">
                <img src={Asset1px} class="footer_logo"/>
                {/* <div class="tagline">Tagline goes here</div> */}
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="container">
            <div class="row">
                <div class="col-md-10 offset-md-2">
                    <div class="d-flex footer_heading_div">
                        <Link to="/privacy_policy" class="footer_heading">Privacy Policy</Link>
                        <div class="footer_vb"></div>
                        <Link to="/terms_conditions" class="footer_heading">Terms And Conditions</Link>
                        <div class="footer_vb"></div>
                        {/* <a href="#" class="footer_heading">POPULAR_ITEMS</a>
                        <div class="footer_vb"></div> */}
                        <Link to="/contactus" class="footer_heading">Contact</Link>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 ">
                    <img src={Asset48} class="footer_logo_icon"/>
                </div>
                <div class="col-md-4">
                    <div class="d-flex mrt" style={{alignItems: "center"}}>
                    <img src={Asset46} class="footer_img"/>
                        <div style={{marginLeft: "20px"}}>
                            <div class="footer_txt">+91 7038684444</div>
                            <div class="footer_txt">+91 9011261500</div>
                        </div>
                    </div>
                    <div class="d-flex mrt" style={{alignItems: "center"}}>
                        <img src={Asset45} class="footer_img"/>
                        <div style={{marginLeft: "20px"}}>
                            <a href="#" class="footer_txt">hotel.apsaramlg@gmail.com</a><br/>
                            {/* <a href="#" class="footer_txt">www.yourwebsite.com</a> */}
                        </div>
                    </div>
                </div>
                <div class="col-md-4 " style={{justifyContent:'center'}}>
                    <div class="d-flex mrt" style={{alignItems: "center"}}>
                    <img src={Asset47} class="footer_img"/>
                        <div style={{marginLeft:'20px'}}>
                            <div class="footer_txt" >02554234444</div>
                           
                        </div>
                    </div>
                    <div class="d-flex mrt" style={{alignItems: "center"}}>
                        <img src={Asset44} class="footer_img"/>
                       
                            <div class="footer_txt" style={{marginLeft:'20px'}}>Hotel Apsara, Opp New Bus Stand, Malegaon, Maharashtra 423203 <br/></div>
                 
                    </div>
                </div>
                <div class="col-md-2 footer_img_div">
                    <div class="d-flex justify-content-evenly">
                        <a href="#"><img src={Asset42} class="footer_img1"/></a>
                        <a href="#"><img src={Asset41} class="footer_img1"/></a>
                    </div>
                    <div class="d-flex justify-content-evenly">
                        <a href="#"><img src={Asset43} class="footer_img1"/></a>
                        <a href="#"><img src={Asset49} class="footer_img1"/></a>
                    </div>
                </div>
            </div>
            <div class="row footer_bottom">
                <div class="col-md-12">
                    <div class="footer_bottom_txt">Copyright 2023 | All Rights Reserved | Design & Developed by<a href="https://www.peaceinfotech.com/" className='foot_linksss'> Peaceinfotech Services Pvt Ltd</a></div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Footer