import React, { useState } from "react";
import Asset19 from "../images/Asset 19.png";
import Asset21 from "../images/Logo.png";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ProfilePage from "./ProfilePage";
import {motion} from 'framer-motion'

function OtpPage(props) {
  //Varify OTP
  const [otpValue, setOtpValue] = useState("");
  const [otpPage, setOtpPage] = useState(true);
  const [profilePage, setProfilePage] = useState("");
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);

  const navigate = useNavigate();
  const onKeyPressEvent2 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const varifyOtp = async (e) => {
    e.preventDefault();
    if (!otpValue) {
      toast.error("Please Enter OTP", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
    }
    if (otpValue.length < 4) {
      // alert("Plaese Enter 6 digits OTP Number");
      // props.showAlert("Plese Enter 6 digits OTP", "danger");
      // toast.error('Please Enter Correct OTP', {
      //   position: "top-center",
      //   autoClose: 5000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: false,
      //   draggable: true,
      //   progress: undefined,
      //   });
      e.preventDefault();
    } else {
      const formData = new FormData();
      formData.append("mobile", props.mobileNumber);
      formData.append("otp", otpValue);
      // const url = "https://192.168.1.11/vipdoor/UserApi/verify_otp";
      const url = "https://hotelapsara.co/admin/UserApi/verifyotp";

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
          // body: JSON.stringify({ title: "React POST Request Example" }),
        };

        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            console.log(response);

            // localStorage.setItem(
            //   "user_name",
            //   response.data.result.user_firstname
            // );
            // setDefaultNumb(response.data.result[0].mobile_no);
            if (response.data.status === false) {
              toast.error("OTP is Wrong", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setOtpPage(true);
            } else if (response.data.profile.user_firstname === "") {
              localStorage.setItem(
                "mobile_number",
                response.data.profile.user_mobile
              );
              localStorage.setItem("user_id", response.data.profile.user_id);
              setProfilePage(true);
              setOtpPage(false);
              toast.info("Please Fill Following Information", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              // props.showAlert("Please Fill Following Information", "info");
              localStorage.setItem("user_id", response.data.result[0].user_id);
              localStorage.setItem(
                "user_name",
                response.data.reuslt[0].user_firstname
              );
            } else {
              toast.success("You are LoggedIn Successfully", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              localStorage.setItem(
                "mobile_number",
                response.data.profile.user_mobile
              );
              localStorage.setItem("user_id", response.data.profile.user_id);
              localStorage.setItem(
                "user_name",
                response.data.profile.user_firstname
              );
              localStorage.setItem(
                "user_email",
                response.data.profile.user_email
              );
              // navigate("/home");
              navigate(-1);

            }
          });
        // console.log(userId);

        // localStorage.setItem("MobileNumber", mobileNumb);
      } catch (error) {
        console.log(error);
        if (error.response.data.status === false) {
          toast.error("OTP is Wrong !", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setOtpPage(true);
        }
        setLoading(false);
      }
    }
    // console.log(profileFirstName);
  };

  //Resend OTP
  const resendOtp = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("mobile", props.mobileNumber);

    const url = "https://hotelapsara.co/admin/UserApi/resendotp";
    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      setResetLoading(true);
      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });

      setResetLoading(false);
      setOtpPage(true);
      setProfilePage(false);
      // alert("OTP has Resend Successfully");
      // props.showAlert("OTP has Resend Successfully", "success");
      toast.success("OTP has Resend Successfully", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (error) {
      setResetLoading(false);
    }
  };

  return (
    <div>
      {otpPage && (
        <>
        <form onSubmit={varifyOtp}>
        <div className="login_container" initial={{x:"100vw"}}
        animate={{x:0}}
        transition={{delay:0.2,duration:0.5}}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="card login_card" style={{padding:'30px'}}>
                <div className="card-img-top">
                  <img src={Asset21} className="login_img" alt="img" />
                </div>
                <div className="card-body lodin_card_body">
                
                    <label for="login_input" className="form-label login_label">
                      Enter OTP
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="login_mob_img">
                        <img src={Asset19} alt="img" />
                      </span>
                      <input
                        type="password"
                        className="form-control login_input"
                        id="login_input"
                        placeholder="OTP"
                        onKeyPress={onKeyPressEvent2}
                        maxLength="4"
                        minLength="4"
                        value={otpValue}
                        onChange={(e) => {
                          setOtpValue(e.target.value);
                        }}
                        style={{ padding: "8px" }}
                      />
                    </div>
                    <div className="text-center">
                      {!loading && (
                        <button type="submit" className="btn login_btn">
                          Verify OTP
                        </button>
                      )}
                      {loading && (
                        <button
                          className="btn login_btn"
                          type="button"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Otp Verifying...
                        </button>
                      )}
                      {/* <button type="submit" className="btn login_btn">
                        Verify OTP
                      </button> */}
                    </div>
                    <div className="text-center">
                      {!resetLoading && (
                        <button
                          type="submit"
                          className="btn login_btn"
                          onClick={resendOtp}
                        >
                          Resend OTP
                        </button>
                      )}
                      {resetLoading && (
                        <button
                          className="btn login_btn"
                          type="button"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Resending Otp...
                        </button>
                      )}
                      {/* <button
                        type="submit"
                        className="btn login_btn"
                        onClick={resendOtp}
                      >
                        Resend OTP
                      </button> */}
                    </div>
            
                </div>
              </div>
            </div>
          </div>
        </div>
</form>
        </>
        
       
      )}
      {profilePage && <ProfilePage mobileNumber={props.mobileNumber} />}
    </div>
  );
}

export default OtpPage;
