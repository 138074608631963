import React from "react";
import Asset34 from '../images/Asset 34.png'
import { motion } from "framer-motion";

function CancelledModal(props) {
  console.log(props);
  let modalStyle = {
    display: "block",
  };
  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };

  return (
    <div>
      <div
        class="modal"
        show
        fade
        style={modalStyle}
        variants={dropIn}
        initial="hidden"
        animate="visible"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content modal-1">
            {/* <div class="modal-header">
              <h3 class="modal-title" id="exampleModalToggleLabel">
                <div className=""></div>
              </h3>
              <button
                type="button"
                class="btn-close"
                onClick={props.hide}
              ></button>
            </div> */}
            <div class="modal-body text-center">
              <img src={Asset34} alt="cancel_img"className="mb-2"/>
              <p style={{color:'red'}} className="mb-2"> Yo Cannot Cancel This Order</p>
              <button className="btn login_btn" onClick={props.hide} style={{border:"1px solid red"}}>OK</button>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancelledModal;
