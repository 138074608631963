import React, { useEffect, useState } from "react";
import Asset21 from "../images/Logo.png";
import Asset18 from "../images/Asset 18.png";
import { toast } from "react-toastify";
import axios from "axios";
import OtpPage from "./OtpPage";
import ProfilePage from "./ProfilePage";
import MenuBar from "../Home/MenuBar";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";

function UserLogin() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [numberPage, setNumberPage] = useState(true);
  const [otpPage, setOtpPage] = useState(false);
  const [profilePage, setProfilePage] = useState(false);

  const [loading, setLoading] = useState(false);

  const onKeyPressEvent2 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const login = async (e) => {
    e.preventDefault();
    if (!mobileNumber) {
      // alert("Mobile Number Is Required");
      // props.showAlert("Please Enter Mobile Number", "danger");
      toast.error("Please Enter Mobile Number", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      e.preventDefault();
      setNumberPage(true);
      setOtpPage(false);
    }
    if (mobileNumber.length < 10) {
      // alert("please Enter 10 digits Number");
      // props.showAlert("Please Enter Mobile Number", "danger");
      // toast.error('Please Enter Mobile Number', {
      //   position: "top-center",
      //   autoClose: 5000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: false,
      //   draggable: true,
      //   progress: undefined,
      //   });
      e.preventDefault();
      setNumberPage(true);
      setOtpPage(false);
    } else {
      const formData = new FormData();
      formData.append("mobile", mobileNumber);

      // const url = "https://192.168.1.11/vipdoor/UserApi/send_otp";
      const url = "https://hotelapsara.co/admin/UserApi/signup";
      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        setLoading(true);
        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });

        setLoading(false);
        setOtpPage(true);
        setNumberPage(false);
        setProfilePage(false);
        toast.success("OTP Send Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } catch (error) {
        setLoading(false);
      }
    }
  };

useEffect(()=>{
window.scroll(0,0);
},[])

  return (
    <div>
      <MenuBar/>
      <Header/>
      {numberPage && (
        <>
        <form onSubmit={login}> 
        <div className="login_container" >
            <div className="container">
              <div className="row justify-content-center">
                <div className="card login_card" style={{padding:'30px'}}>
                  <div className="card-img-top">
                    <img src={Asset21} className="login_img" alt="img" />
                  </div>
                  <div className="card-body lodin_card_body">
                   
                      <label
                        for="login_input"
                        className="form-label login_label"
                      >
                        Mobile Number
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="login_mob_img">
                          <img src={Asset18} alt="img" />
                        </span>
                        <input
                          type="text"
                          className="form-control login_input"
                          id="login_input"
                          placeholder="Enter Your Mobile No."
                          onKeyPress={onKeyPressEvent2}
                          maxLength="10"
                          minLength="10"
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                        />
                      </div>
                      <div className="text-center">
                        {!loading && (
                          <button type="submit" className="btn login_btn">
                            Send OTP
                          </button>
                        )}
                        {loading && (
                          <button
                            className="btn login_btn"
                            type="button"
                            disabled
                          >
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>{" "}
                            Otp Sending...
                          </button>
                        )}
                      </div>
                
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
          
        </>
      )}
      {otpPage && (
        <>
        <div style={{backgroundColor:'#f4ede4 '}}>
        <OtpPage mobileNumber={mobileNumber} />
        </div>
        
        </>
      )}
      {profilePage && (
        <>
        <div style={{backgroundColor:'#f4ede4 '}}>
        <ProfilePage mobileNumber={mobileNumber} />
        </div>
        
        </>
      )}
      <Footer/>
    </div>
  );
}

export default UserLogin;
