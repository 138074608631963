import React from "react";
import Asset30 from "../images/Asset 30.png";
import Asset14 from "../images/Asset 14.png";
import { motion } from "framer-motion";

function DescriptionModal(props) {
  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };

  let modalStyle = {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.8)",
  
  };
  return (
    <div>
      <div
        class="modal"
        show
        fade
        style={modalStyle}
        variants={dropIn}
        initial="hidden"
        animate="visible"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content modal-1">
            <div class="modal-header">
              <h3 class="modal-title" id="exampleModalToggleLabel">
                {props.title}
              </h3>
              <button
                type="button"
                class="btn-close"
                onClick={props.hide}
              ></button>
            </div>
            <div class="modal-body">
              <p>{props.desc}</p>
              <div class="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between">
                    <h2>Rs. {props.price}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DescriptionModal;
