import React, { useState } from "react";
import Asset38 from "../images/Asset 38.png";
import Asset301 from "../images/Asset 301.png";
import { toast } from "react-toastify";
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import {motion} from 'framer-motion'


function ProfilePage(props) {

  let user_id = localStorage.getItem("user_id");

  const [profilePicture, setProfilePicture] = useState("");
  const [profileFirstName, setProfileFirstName] = useState("");
  const [profileLastName, setProfileLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [profilePage, setProfilePage] = useState(true);
  const [loading, setLoading] = useState("");

  const onKeyPressEvent2 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };
  const navigate = useNavigate();

  const uploadPicture = (e) => {
    // setSendImage(e.target.files[0]);
    setProfilePicture(e.target.files[0]);
  };

  const newProfile = async (e) => {
    e.preventDefault();
    if (
      !profileFirstName
    ) {
      // alert("All Feilds Are Required");
      // props.showAlert("All Feilds are Required", "danger");
      toast.error('First Name is Required!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme:'colored'
        });
      e.preventDefault();
      setProfilePage(true);
    } 
    else if(!profileLastName){
      toast.error('Last Name is Required!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme:'colored'
        });
      e.preventDefault();
      setProfilePage(true);
    }
    else if(!email){
      toast.error('Email is Required!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme:'colored'
        });
      e.preventDefault();
      setProfilePage(true);
    }

    else {
      const url = "https://hotelapsara.co/admin/UserApi/updateprofile";

      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("first_name", profileFirstName);
      formData.append("last_name", profileLastName);
      formData.append("email", email);
    
      {!profilePicture?
         formData.append("profile", profilePicture)
         :   formData.append("profile", '')}

      formData.append("mobile", props.mobileNumber);
   
      // formData.append("profile", `assets/images/profile/profile_img.png`);
      // formData.append("profile", Image.picture);

      // const url = "https://192.168.1.16/vipdoor/UserApi/signup";
      // const url = "https://vipdoorservices.com/UserApi/signup"
      // const url = "https://vipdoorservices.com/UserApi/update_user_by_id";
      // const url ="https://192.168.1.11/vipdoor/UserApi/update_user_by_id"

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        const data = await axios
          .post(url, formData, config)
          .then((response) => {
            // console.log(response.data.user_id);
            console.log(response);
            setProfileFirstName(response.data.profile.user_firstname);
            localStorage.setItem(
              "user_name",
              response.data.profile.user_firstname
            );
            localStorage.setItem(
              "user_email",
              response.data.profile.user_email
            );
            toast.success('You are LoggedIn Successfully!', {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme:'colored'
              });
            navigate(-2);
       
          })
          .catch((error) => {
            console.log(error);
          });
        // console.log(data);
        // setProfilePage(true);
        // alert("You Are LoggedIn Successfully");
        // props.showAlert("You are LoggedIn Successfully", "success");
       
        // setUserId(userId);
      } catch (error) {
        setLoading(false);
      }
    }
  };


  return (
    <div>
      <>
      {profilePage && 
       <form onSubmit={newProfile}>
       <div class="profile_container" animate={{x:0}}
        transition={{delay:0.2,duration:0.5}}>
         <div class="container inner_part">
           <div class="row">
             <div class="col-md-3 text-center">
               <div class=" profile_col_1" style={{ marginTop: "-115px" }}>
                 <div class="profile-section ">
                   {!profilePicture ? (
                     <>
                       <img src={Asset301} alt="img"></img>
                     </>
                   ) : (
                     <>
                       <img
                         src={
                           profilePicture
                             ? URL.createObjectURL(profilePicture)
                             : null
                         }
                         alt={profilePicture ? profilePicture.name : null}
                       />
                     </>
                   )}
                 </div>
                 <div
                   className="col-md-2 plus_btn"
                   style={{ marginTop: "-40px", marginLeft: "89px" }}
                 >
                   <div class="upload-btn-wrapper  ">
                     <img
                       class=""
                       src={Asset38}
                       style={{ height: "25px", width: "25px" }}
                       alt="img"
                     ></img>
                     <input
                       type="file"
                       name="myfile"
                       accept="image/jpg, image/jpeg"
                       onChange={uploadPicture}
                     />
                   </div>
                 </div>
               </div>
             </div>
             <div className="row" style={{ marginLeft: "0px" }}>
               <div className="col-md-6">
                 <div class="mb-3">
                   <label
                     class="form-label profile_label"
                     style={{
                       color: "#7D453D",
                       fontSize: "16px",
                       fontWeight: "bold",
                     }}
                   >
                     First Name
                   </label>
                   <input
                     type="text"
                     class="form-control profile_input"
                     value={profileFirstName}
                     onChange={(e) => {
                       setProfileFirstName(e.target.value);
                     }}
                     style={{
                       backgroundColor: "transparent",
                       border: "1px solid #DDD6CE",
                       borderRadius: "10px",
                       padding: "7px",
                     }}
                   />
                 </div>
               </div>
               <div className="col-md-6">
                 <div class="mb-3">
                   <label
                     class="form-label profile_label"
                     style={{
                       color: "#7D453D",
                       fontSize: "16px",
                       fontWeight: "bold",
                     }}
                   >
                     Last Name
                   </label>
                   <input
                     type="text"
                     class="form-control profile_input"
                     value={profileLastName}
                     onChange={(e) => {
                       setProfileLastName(e.target.value);
                     }}
                     style={{
                       backgroundColor: "transparent",
                       border: "1px solid #DDD6CE",
                       borderRadius: "10px",
                       padding: "7px",
                     }}
                   />
                 </div>
               </div>
             </div>
             <div className="row" style={{ marginLeft: "0px" }}>
               <div className="col-md-6">
                 <div class="mb-3">
                   <label
                     class="form-label profile_label"
                     style={{
                       color: "#7D453D",
                       fontSize: "16px",
                       fontWeight: "bold",
                     }}
                   >
                     Email
                   </label>
                   <input
                     type="email"
                     class="form-control profile_input"
                     value={email}
                     onChange={(e) => {
                       setEmail(e.target.value);
                     }}
                     style={{
                       backgroundColor: "transparent",
                       border: "1px solid #DDD6CE",
                       borderRadius: "10px",
                       padding: "7px",
                     }}
                   />
                 </div>
               </div>
               <div className="col-md-6">
                 <div class="mb-3">
                   <label
                     class="form-label profile_label"
                     style={{
                       color: "#7D453D",
                       fontSize: "16px",
                       fontWeight: "bold",
                     }}
                   >
                     Mobile no.
                   </label>
                   <input
                     type="text"
                     class="form-control profile_input"
                     id="zip"
                  value={props.mobileNumber}

                     maxLength="10"
                     minLength="10"
                     style={{
                       backgroundColor: "transparent",
                       border: "1px solid #DDD6CE",
                       borderRadius: "10px",
                       padding: "7px",
                     }}
                     disabled
                   />
                 </div>
               </div>
               <div className="text-center">
                 <button type="submit" className="btn login_btn">
                   Save
                 </button>
               </div>
          
             </div>
           </div>
         </div>
       </div>
     </form>
      }
       
      </>
    </div>
  );
}

export default ProfilePage;
