import React, { useState, useEffect } from "react";
import Footer from "../../Footer/Footer";
import Header from "../Header";
import MenuBar from "../MenuBar";
import axios from "axios";
import Asset29 from "../../images/Asset 29.png";
import Asset32 from "../../images/Asset 32.png";
import Asset33 from "../../images/Asset 33.png";
import DeleteAddressModal from "./DeleteAddressModal";
import EditAddressModal from "./EditAddressModal";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import MyCart from "../../MyCart/MyCart";

function AddressAddToCart() {
  let user_id = localStorage.getItem("user_id");

  const navigate = useNavigate();
  //For Radio Button
  const [selectedID, setID] = useState("");
  const [cartPage, setCartPage] = useState(false);

  const [addressPage, setAddressPage] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [addressType, setAddressType] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPinCode] = useState("");
  const [addressData, setAddressData] = useState([]);
  const [modal, setModal] = useState(false);
  const [tempData, setTempData] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState("");

  const onKeyPressEvent2 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  //Get Address
  const getAddressInfo = () => {
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = `https://hotelapsara.co/admin/UserApi/getuserAddress?user_id=${user_id}`;
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    setLoading(true);
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response);
        setLoading(false);
        setAddressData(response.data.data);
        // toast.info("Please Select One Address", {
        //   position: "top-center",
        //   autoClose: 3000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   pauseOnHover: false,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
      })

      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAddressInfo();
  }, []);

  //For Address Section
  const addressSectionToggle = () => {
    if (addressPage === false) {
      setAddressPage(true);
    } else {
      setAddressPage(false);
    }
  };

  //Add Address
  const addAddress = async () => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("address_type", addressType);
    formData.append("address", address);
    formData.append("address_name", name);
    formData.append("city", city);
    formData.append("pincode", pincode);
    formData.append("country", "India");
    formData.append("mobile", mobile);
    formData.append("state", state);

    const url = "https://hotelapsara.co/admin/UserApi/addUserAddress";
    const config = {
      headers: { "content-type": "multipart/form-data" },
      body: formData,
    };
    setButtonLoading(true);
    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response);
        setButtonLoading(false);

        window.location.reload(false);
        toast.success("Address Is Added", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) => {
        console.log(error);
        setButtonLoading(false);
      });
  };

  //Get Data for Delete
  const getModalData = (address, state, pincode, city, address_id) => {
    let modalData = [address, state, pincode, city, address_id];

    setTempData((item) => [1, ...modalData]);
    return setModal(true);
  };

  //Get Data For Update
  const getAddressAllData = (
    address,
    state,
    pincode,
    city,
    address_id,
    mobile,
    address_name,
    address_type
  ) => {
    let editModalData = [
      address,
      state,
      pincode,
      city,
      address_id,
      mobile,
      address_name,
      address_type,
    ];
    localStorage.setItem("address_values", JSON.stringify(editModalData));
    // localStorage.setItem("address_values", editModalData);
    if (localStorage.getItem("address_values")) {
      navigate(-1);
      toast.success("Address Is Selected", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const updatename = (e) => {
    setName(e.target.value);
  };
  const updateaddress = (e) => {
    setAddress(e.target.value);
  };
  const updatecity = (e) => {
    setCity(e.target.value);
  };
  const updateState = (e) => {
    setState(e.target.value);
  };
  const updatePincode = (e) => {
    setPinCode(e.target.value);
  };
  const updateAddressType = (e) => {
    setAddressType(e.target.value);
  };
  const updateMobile = (e) => {
    setMobile(e.target.value);
  };

  return (
    <div>
      {loading && loading ? (
        <Loader />
      ) : (
        <>
          <MenuBar />
          <Header />
          <div style={{ backgroundColor: "#f4ede4" }}>
            <div className="container">
              <label
                class="form-label profile_label mt-3"
                style={{
                  color: "#7D453D",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                1. Shipping Billing Information
              </label>

              {addressData &&
                addressData.map((item) => {
                  //   console.log(item);
                  return (
                    <>
                      <div
                        class="card"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <div class="card-body">
                          <p class="card-title">
                            {" "}
                            <span
                              style={{
                                color: "#7D453D",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              Address:
                            </span>{" "}
                            {item.address}
                          </p>
                          <p class="card-title">
                            <span
                              style={{
                                color: "#7D453D",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              Mobile Number:
                            </span>{" "}
                            {item.mobile}
                          </p>

                          <p class="card-title">
                            <span
                              style={{
                                color: "#7D453D",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              City:
                            </span>{" "}
                            {item.city}
                          </p>
                          <p class="card-text">
                            <span
                              style={{
                                color: "#7D453D",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              State:
                            </span>{" "}
                            {item.state}
                          </p>
                          <p class="card-text">
                          <span
                              style={{
                                color: "#7D453D",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              Pincode:
                            </span>{" "}{item.pincode}</p>

                          <button
                            type="submit"
                            class="btn login_btn "
                            style={{ backgroundColor: "white" }}
                            onClick={() =>
                              getAddressAllData(
                                item.address,
                                item.state,
                                item.pincode,
                                item.address_id,
                                item.city,
                                item.mobile
                              )
                            }
                          >
                            Select
                          </button>
                          {/* 
                          <input
                            role="button"
                            id={item.address_id}
                            type="radio"
                            name={item.address}
                            checked={item.address_id === selectedID}
                            onChange={() => setID(item.address_id)}
                          /> */}
                        </div>
                      </div>
                    </>
                  );
                })}

              <hr />

              <div
                class="form-label profile_label"
                style={{
                  color: "#7D453D",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                OR
              </div>
              <button
                type="submit"
                className="btn login_btn mb-5 mt-5"
                onClick={addressSectionToggle}
                style={{ backgroundColor: "white" }}
              >
                + Add New Address
              </button>

              {addressPage && (
                <form>
                  <div className="row" style={{ marginLeft: "0px" }}>
                    <div className="col-md-6">
                      <div class="mb-3">
                        <label
                          class="form-label profile_label"
                          style={{
                            color: "#7D453D",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          class="form-control profile_input"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid #DDD6CE",
                            borderRadius: "10px",
                            padding: "7px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="mb-3">
                        <label
                          class="form-label profile_label"
                          style={{
                            color: "#7D453D",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Mobile
                        </label>
                        <input
                          type="text"
                          class="form-control profile_input"
                          onChange={(e) => setMobile(e.target.value)}
                          value={mobile}
                          onKeyPress={onKeyPressEvent2}
                          maxLength="10"
                          minLength="10"
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid #DDD6CE",
                            borderRadius: "10px",
                            padding: "7px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ marginLeft: "0px" }}>
                    <div className="col-md-6">
                      <div class="mb-3">
                        <label
                          class="form-label profile_label"
                          style={{
                            color: "#7D453D",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Address
                        </label>
                        <input
                          type="text"
                          class="form-control profile_input"
                          onChange={(e) => setAddress(e.target.value)}
                          value={address}
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid #DDD6CE",
                            borderRadius: "10px",
                            padding: "7px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="mb-3">
                        <label
                          class="form-label profile_label"
                          style={{
                            color: "#7D453D",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          City
                        </label>
                        <input
                          type="text"
                          class="form-control profile_input"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid #DDD6CE",
                            borderRadius: "10px",
                            padding: "7px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ marginLeft: "0px" }}>
                    <div className="col-md-6">
                      <div class="mb-3">
                        <label
                          class="form-label profile_label"
                          style={{
                            color: "#7D453D",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Address Type
                        </label>
                        <input
                          type="text"
                          class="form-control profile_input"
                          onChange={(e) => setAddressType(e.target.value)}
                          value={addressType}
                          placeholder="Home, Office etc."
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid #DDD6CE",
                            borderRadius: "10px",
                            padding: "7px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="mb-3">
                        <label
                          class="form-label profile_label"
                          style={{
                            color: "#7D453D",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          State
                        </label>
                        <input
                          type="text"
                          class="form-control profile_input"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid #DDD6CE",
                            borderRadius: "10px",
                            padding: "7px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="mb-3">
                        <label
                          class="form-label profile_label"
                          style={{
                            color: "#7D453D",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Pincode
                        </label>
                        <input
                          type="text"
                          class="form-control profile_input"
                          value={pincode}
                          onKeyPress={onKeyPressEvent2}
                          maxLength="6"
                          minLength="6"
                          onChange={(e) => setPinCode(e.target.value)}
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid #DDD6CE",
                            borderRadius: "10px",
                            padding: "7px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {!buttonLoading && (
                    <button
                      type="submit"
                      class="btn login_btn mb-5 mt-5"
                      style={{ backgroundColor: "white" }}
                      onClick={addAddress}
                    >
                      Continue
                    </button>
                  )}
                  {buttonLoading && (
                    <button
                      className="btn login_btn mb-5 mt-5"
                      type="button"
                      disabled
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      Adding Address...
                    </button>
                  )}
                </form>
              )}
            </div>
          </div>

          <Footer />
        </>
      )}
    </div>
  );
}

export default AddressAddToCart;
