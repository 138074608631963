import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Home/Header'
import MenuBar from '../Home/MenuBar'

function Contactus() {
  return (
    <div>
        <MenuBar/>
        <Header/>
        <div class="courses-container mb-5" style={{ marginTop: "50px" }}>
        <div class="course">
          <div className="row">
          <div class="course-preview col-md-4">
            <div style={{fontSize:'22px'}}>Our Address</div><br/>
            <div style={{fontSize:'14px'}}>
            Hotel Apsara, Opp New Bus Stand, Malegaon, Maharashtra 423203 
            </div>
            <br/>
            
            {/* <div style={{fontSize:'14px'}}>
              Navi Mumbai - Shop No 27, Ground Floor, Realtech Park, Sector 30A,
              Vashi, Navi Mumbai -400703.
            </div> */}
          </div>
          <div class="course-info col-md-8">
            <form class="row g-3">
              <div class="col-md-6 p-1">
               
                <input type="text" class="form-control" id="inputEmail4" placeholder='Enter Your Name'/>
              </div>
              <div class="col-md-6 p-1">
              
                <input type="number" class="form-control" id="inputPassword4" placeholder='Enter Your Number'/>
              </div>
              <div class="col-md-4 p-1">
       
                <input type="email" class="form-control" id="inputPassword4" placeholder='Enter Your Email'/>
              </div>

              <div class="col-md-8 p-1">
        
                <input
                  type="text"
                  class="form-control"
                  id="inputAddress"
                  placeholder='Enter Your Address'
                />
              </div>

              <div class="col-md-4 p-1">
            
                <input type="text" class="form-control" id="inputCity" placeholder='Enter Your City'/>
              </div>
              <div class="col-md-4 p-1">
            
                <select
                  className="form-control"
                  id="input-country"
                  name="state"
                >
                  <option value="">Select Your State</option>

                  <option value="Andhra Pradesh">Andhra Pradesh</option>
                  <option value="Andaman and Nicobar Islands">
                    Andaman and Nicobar Islands
                  </option>
                  <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                  <option value="Assam">Assam</option>
                  <option value="Bihar">Bihar</option>
                  <option value="Chandigarh">Chandigarh</option>
                  <option value="Chhattisgarh">Chhattisgarh</option>
                  <option value="Dadar and Nagar Haveli">
                    Dadar and Nagar Haveli
                  </option>
                  <option value="Daman and Diu">Daman and Diu</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Lakshadweep">Lakshadweep</option>
                  <option value="Puducherry">Puducherry</option>
                  <option value="Goa">Goa</option>
                  <option value="Gujarat">Gujarat</option>
                  <option value="Haryana">Haryana</option>
                  <option value="Himachal Pradesh">Himachal Pradesh</option>
                  <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                  <option value="Jharkhand">Jharkhand</option>
                  <option value="Karnataka">Karnataka</option>
                  <option value="Kerala">Kerala</option>
                  <option value="Madhya Pradesh">Madhya Pradesh</option>
                  <option value="Maharashtra">Maharashtra</option>
                  <option value="Manipur">Manipur</option>
                  <option value="Meghalaya">Meghalaya</option>
                  <option value="Mizoram">Mizoram</option>
                  <option value="Nagaland">Nagaland</option>
                  <option value="Odisha">Odisha</option>
                  <option value="Punjab">Punjab</option>
                  <option value="Rajasthan">Rajasthan</option>
                  <option value="Sikkim">Sikkim</option>
                  <option value="Tamil Nadu">Tamil Nadu</option>
                  <option value="Telangana">Telangana</option>
                  <option value="Tripura">Tripura</option>
                  <option value="Uttar Pradesh">Uttar Pradesh</option>
                  <option value="Uttarakhand">Uttarakhand</option>
                  <option value="West Bengal">West Bengal</option>
                </select>
              </div>
              <div class="col-md-4 p-1">
          
                <input type="text" class="form-control" id="inputZip" placeholder='Enter Your Zipcode' />
              </div>

              <div class="text-end">
                <button type="submit" class="btn_order ">
                  Send
                </button>
              </div>
            </form>
          </div>
          </div>
        
        </div>
      </div>
        <Footer/>
    </div>
  )
}

export default Contactus