import React, { useState, useEffect, useRef } from "react";
import Footer from "../Footer/Footer";
import Header from "../Home/Header";
import MenuBar from "../Home/MenuBar";
import axios from "axios";
import Loader from "../Home/Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import CancelReasonModal from "./CancelReasonModal";
import CancelledModal from "./CancelledModal";
// import { motion } from "framer-motion";
import Asset95 from "../images/Asset95.png";
import moment from "moment";

function OrderPage() {
  let user_id = localStorage.getItem("user_id");
  let order_id = localStorage.getItem("order_id");
  //For Active Buttons

  const [activeButton, setActiveButton] = useState("One");

  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [orderInsideData, setOrderInsideData] = useState([]);
  const [cancelReasonModal, setCancelReasonModal] = useState(false);
  const [itemWillCancelled, setItemWillCancelled] = useState(false);
  const [cancelledModal, setCancelledModal] = useState(false);
  const [errorData, setErrorData] = useState(false);
  const [nullMessage, setNullMessage] = useState(false);
  const [deliveryPage, setDeliveryPage] = useState(false);
  const [popularData, setPopularData] = useState("");

  //For Timer Section
  const Ref = useRef(null);
  const [timer, setTimer] = useState("00:00:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor(((total / 1000) * 60 * 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the begining of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("00:00:10");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 10);
    return deadline;
  };
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  //For Active Passive Buttons

  const navigate = useNavigate();
  const clickedButtonHandler = (e) => {
    // console.log(e.target);
    // const { name } = e.target;
    setActiveButton("Two");
    navigate("/home/my_orders/cancelled_order");

    // console.log(activeButton);
  };

  //Get Orders
  const getDataByID = async () => {
    setLoading(true);
    // setInterval(() => {
    //   console.log("hello")
    // }, 1000)

    // const url = `https://192.168.1.16/vipdoor/UserApi/service_data?service_id=${id}`;
    const url = `https://hotelapsara.co/admin/UserApi/orders?user_id=${user_id}`;

    axios
      .get(url)
      .then((response) => {
        console.log(response);
        if (response.data.message !== "Orders Found") {
          setNullMessage(true);
          setLoading(false);
          setDeliveryPage(false);
        } else {
          setLoading(false);
          setDeliveryPage(true);

          setOrderData(response.data.orders);
        }
      })

      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if(!user_id){
    navigate("/login");
    }
    else{
      getDataByID();
      clearTimer(getDeadTime());
    }
    
  }, []);

  const getOrderById = async (user_id, order_id) => {
    const url = `https://hotelapsara.co/admin/UserApi/getOrderedItemsofUser?user_id=${user_id}&order_id=${order_id}`;
    const apiResponse = await axios.get(url);
    return apiResponse.data.items;
  };

  //For Inside content
  const getDataByOrder_id = (order_id) => {
    let formData = new FormData();

    // const url = `https://192.168.1.16/vipdoor/UserApi/service_data?service_id=${id}`;
    const url = `https://hotelapsara.co/admin/UserApi/getOrderedItemsofUser?user_id=${user_id}&order_id=${order_id}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    setLoading(true);
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response);
        setLoading(false);

        setOrderInsideData(response.data.items);
      })

      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // console.log(orderInsideData)

  //ReasonModal
  const discriptionModalData = (date, time, id) => {
    let modalData = [date, time, id];

    setPopularData((item) => [1, ...modalData]);
    return setCancelReasonModal(true);
  };

  //Cncelled Modal
  const cancelledModalData = (date, time, id) => {
    let modalData = [date, time, id];

    setErrorData((item) => [1, ...modalData]);
    return setCancelledModal(true);
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MenuBar />
          <Header />

          {nullMessage && (
            <>
              <div>
                <div
                  className="text-center p-3"
                  style={{ backgroundColor: "#F7F4F0" }}
                >
                  <img
                    src={Asset95}
                    alt="img"
                    style={{ width: "200px", height: "200p" }}
                  ></img>
                </div>
              </div>
            </>
          )}

          {deliveryPage && (
            <>
              <div
                className="text-center mb-2"
                initial={{ y: -300 }}
                animate={{ y: 0 }}
                transition={{
                  delay: 0.2,
                  duration: 0.5,
                  stiffness: 120,
                  type: "spring",
                }}
              >
                <button className="btn login_btn100 mx-2">Running</button>
                <button
                  className={
                    activeButton === "Two"
                      ? "btn login_btn100 "
                      : "btn login_btn"
                  }
                  onClick={clickedButtonHandler}
                  name="Two"
                >
                  History
                </button>
              </div>

              {orderData &&
                orderData.map((item, i) => {
                  const format1 = "YYYY-MM-DD ";
                  const format2 = "YYYY-MM-DD ";

                  var date1 = new Date(item.order_details.order_date);
                  var date2 = new Date();
                  const dateTime1 = moment(date1).format(format1);
                  const dateTime2 = moment(date2).format(format2);

                  var ApiDate = item.order_details.order_time;
                  // console.log(ApiDate)
                  var TodaysDate = new Date();
                  var time =
                    TodaysDate.getHours() +
                    ":" +
                    TodaysDate.getMinutes() +
                    ":" +
                    TodaysDate.getSeconds();
                  // console.log(time)
                  const str1 = ApiDate.split(":");
                  const str2 = time.split(":");
                  var totalSeconds1 = parseInt(
                    str1[0] + str1[1] + str1[0]
                  );
                  var totalSeconds2 = parseInt(
                    str2[0] + str2[1] + str2[0]
                  );
                  console.log(
                    str2[2] - str1[2]

                  );
                  // const diffTime = totalSeconds2 - totalSeconds1;
                  const diffTime = (totalSeconds2 - totalSeconds1) * 60 / 100;
                  // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                  // console.log(diffTime/ + " milliseconds");
                  // console.log(diffTime ,'time');

                  return (
                    <>
                      {item.order_details.status !== "Cancelled By User" && (
                        <>
                          <div
                            class="card order-history55 pt-3 pb-3"
                            style={{ backgroundColor: "#F7F4F0" }}
                            key={i}
                            initial={{ x: "100vw" }}
                            animate={{ x: 0 }}
                            transition={{
                              delay: 0.2,
                              duration: 0.5,
                              stiffness: 120,
                              type: "spring",
                            }}
                          >
                            <div class="card-body card_delivery">
                              {" "}
                              <h6 class="card-title55 title55">
                                Ordered On {item.order_details.order_date}{" "}
                                {item.order_details.order_time}
                                <span class="status55" style={{
                                  backgroundColor: "white",
                                  padding: "4px 10px",
                                  borderRadius: "5px"
                                }}>
                                  Order:{" "}
                                  {(diffTime >= 60) ? (
                                    <>
                                      <span
                                        class="stat-25"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          cancelledModalData(
                                            item.order_date,
                                            item.order_time,
                                            item.order_id
                                          )
                                        }
                                        whileHover={{ scale: 1.1 }}
                                      >
                                        Cancel ?
                                      </span>{" "}
                                    </>
                                  ) : (
                                    <>
                                      <span
                                        class="stat-25"

                                        onClick={() =>
                                          discriptionModalData(
                                            item.order_details.order_time,
                                            item.order_details.order_id
                                          )
                                        }
                                        whileHover={{ scale: 1.1 }}
                                      >
                                        Cancel ?
                                      </span>{" "}
                                    </>
                                  )}
                                </span>
                              </h6>
                              {item.order_items &&
                                item.order_items.map((items, index) => {
                                  // console.log(items);
                                  return (
                                    <>
                                      <div class="row disc55" key={index}>
                                        <div class="col-3 ">
                                          <img
                                            src={`https://hotelapsara.co/admin/${items.item_image}`}
                                            alt="salman"
                                            class="prod-img55"
                                          />
                                        </div>
                                        <div class="col-9 order-disc55">
                                          <p>
                                            {items.category}
                                            <br />
                                            {items.item_name}
                                            <br />
                                          </p>
                                          <div className="col-md-4">
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                              <div>
                                                Quantity:


                                              </div>
                                              <div style={{ fontSize: "16px" }}>
                                                {items.item_quantity} x{" "}
                                                {items.item_price}
                                              </div>
                                            </div>


                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                              <div> Total Price:{" "}</div>
                                              <div style={{ fontSize: "16px" }}>
                                                {items.total_price_after_discount}{" "}
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              <div class="row detail55">
                                <div class="col-4">
                                  <p>
                                    Order ID:{" "}
                                    <span class="order-span55">
                                      {item.order_details.order_id}
                                    </span>{" "}
                                  </p>
                                </div>

                                <div class="col-3 order-25" >
                                  <p>
                                    Total Amount :{" "}
                                    <span class="order-span55">

                                      {item.order_details.total_amount}
                                    </span>{" "}
                                  </p>
                                </div>
                                <div class="col-5 order-3 text-end">

                                  <p>
                                    Payment{" "}
                                    <span class="order-span55">
                                      {item.order_details.payment_type}
                                    </span>{" "}
                                  </p>
                                </div>
                              </div>
                              <div class="div shipping55">
                                <p>Shipping Address</p>
                                <p>
                                  Name:{" "}
                                  <span class="addr-15">
                                    {item.order_details.username}
                                  </span>
                                </p>
                                <p>
                                  Mobile No:{" "}
                                  <span class="addr-25">
                                    {item.order_details.user_mobile}
                                  </span>
                                </p>
                                <p>
                                  Address:{" "}
                                  <span class="addr-35">
                                    {item.order_details.address},{""}
                                    {item.order_details.city},{" "}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  );
                })}
            </>
          )}

          {cancelReasonModal === true ? (
            <CancelReasonModal
              hide={() => setCancelReasonModal(false)}
              time={popularData[0]}
              id={popularData[2]}
            />
          ) : (
            ""
          )}
          {cancelledModal === true ? (
            <CancelledModal
              hide={() => setCancelledModal(false)}
              time={errorData[2]}
              id={errorData[3]}
            />
          ) : (
            ""
          )}
          <Footer />
        </>
      )}
    </div>
  );
}

export default OrderPage;
