import React from "react";
import Asset27 from "../../images/Asset 27.png";
import Asset14 from "../../images/Asset 14.png";
import Asset20 from "../../images/Asset 20.png";
import Asset28 from "../../images/Asset 28.png";
import Asset13 from "../../images/Asset 13.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Category(props) {
  // console.log(props.setWhishlist);
  const options = {
    margin: 30,
    responsiveclass: true,
    nav: false,
    dots: false,
    autoplay: true,
    // navText: ["Prev", "Next"],
    // navText: ['<img className="" src= {Asset104}>', '<img className="" src= {Asset105}>'],
    smartSpeed: 1000,
    loop: true,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 3,
      },
      700: {
        items: 6,
      },
      1000: {
        items: 6,
      },
    },
  };
  // if(Math.random() > 0.5 ){
  //   return new Error ("Test Error Boundry")
  // }

  return (
    <div>
      <div className="menu_container">
        <div className="container">
          <div className="row">
            <div className="d-flex justify-content-between">
              <h2 className="menu_heading">All Categories</h2>
              {/* <Link  to={`/home/category/${item.main_category_id}/all_items`} className="menu_link ">
                View All
              </Link> */}
            </div>
            <OwlCarousel classNameName="owl-theme" {...options}>
              {props.getCategory.map((item, i) => {
                // console.log(props.setWhishlist[i].menu_id)
                // console.log(item)
                return (
                  <>
                    <motion.div
                      className="item"
                      whileHover={{
                        scale: 0.9,
                      }}
                    >
                      <div className="card menu_card">
                        <div className="card_image">
                          <Link
                            to={`/home/category/${item.main_category_id}/all_items`}
                          >
                            <img
                              src={`https://hotelapsara.co/admin/${item.main_category_image}`}
                              className="menu_img"
                              alt="img"
                            />
                          </Link>
                        </div>

                        <div className="card-body">
                          <h6
                            className="menu_card_heading"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {item.main_category_title.length > 12
                              ? `${item.main_category_title.substring(0, 13)}...`
                              : item.main_category_title}
                            {/* {item.main_category_title.slice(0, 12)}... */}
                          </h6>
                          <p className="menu_disc">
                          {item.main_category_description.length > 20
                              ? `${item.main_category_description.substring(0, 21)}...`
                              : item.main_category_description}
                            {/* {item.main_category_description.slice(0, 15)}... */}
                          </p>
                          {/* <p className="menu_price">Rs. {item.menu_price}</p> */}
                        </div>
                      </div>
                    </motion.div>
                  </>
                );
              })}

              {/* <div className="item">
                <div className="card menu_card">
                  <img src={Asset28} className="menu_img" />
                  <div className="card-body">
                    <h6 className="menu_card_heading">Spinach Rice</h6>
                    <p className="menu_disc">Description Goes here</p>
                    <p className="menu_price">Rs. 199/-</p>
                    <div className="d-flex justify-content-between">
                      <img src={Asset14} className="menu_add_product_img" />
                      <img src={Asset13} className="menu_like_product_img" />
                    </div>
                  </div>
                </div>
              </div> */}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Category;
