import React, { useState } from "react";
import Asset22 from "../images/Asset 22.jpg";
import Asset26 from "../images/Asset 26.jpg";
import Asset25 from "../images/Asset 25.jpg";
import Asset24 from "../images/Asset 24.jpg";
import { motion } from "framer-motion";

function Carausel(props) {
  const [isActive, setIsActive] = useState("carausel-item active");
  const [nActive, setNActive] = useState("carausel-item");

  return (
    <div>
      <div>
      <div
        class="container-fluid p-0 marg_top"
        
      >
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            {props.getBanners.map((eachFile, index) => {
              return (
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={index}
                  class="active"
                  aria-current="true"
                  aria-label={`Slide ${index + 1}`}
                ></button>
              );
            })}
          </div>

          <div class="carousel-inner">
            {props.getBanners.map((eachFile, index) => {
              return (
                <div
                  className={
                    index === 0 ? "carousel-item active" : "carousel-item "
                  }
                  key={`${eachFile}img`}
                >
                  <img
              
              src={`https://hotelapsara.co/admin/${eachFile.banner_image}`}
                    class="d-block img-fluid  w-100 "
                    alt="banner"
                    key={index}
                  
                  />
                </div>
              );
            })}
          </div>

          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Carausel;
